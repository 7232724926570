<template>
   <v-dialog :value="!!uid" persistent max-width="600" >
      <v-card :loading="loading">
         <v-card-title>
            {{uid === 'new'?'Buat':'Edit'}} User
         </v-card-title>
         <v-card-text>
            <v-form ref="form">
               <v-text-field label="Name" v-model="usr.name" @input="$set(update,'name', arguments[0])" :rules="[v => !!v || 'Nama lengkap harus diisi']"/>
               <v-text-field :disabled="uid !== 'new'" label="Email" v-model="usr.email" :rules="[v => !!v || 'Email diperlukan']" />
               <v-switch v-if="uid !== 'new' && uid !== 'profile'" v-model="ch_passwd" label="Ubah password" warning />
               <template v-if="ch_passwd || uid ==='new'">
                  <v-text-field v-if="uid === 'profile'"  label="Password Lama" v-model="usr.password0" />
                  <v-text-field type="password" label="Password" v-model="usr.password" :rules="[v => !!v || 'Password harus diisi', v => !!v && (v.length > 4) || 'Password minimal 5 karakter']"/>
                  <v-text-field type="password" label="Password Konfirmasi" v-model="usr.retype_password" :rules="[checkPasswd]"/>
               </template>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer/>
            <v-btn width="120" outlined rounded color="secondary" @click="$emit('close')">tutup</v-btn>
            <v-btn width="180" :disabled="no_update" :loading="load_save" outlined rounded color="primary" @click="save">simpan</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import config from '../config';
import api from '../api';

export default {
   props: ['uid'],
   data: () => ({
      usr: {},
      pwd: {},
      ch_passwd: false,
      files: [],
      update: {},
      loading: false,
      load_save: false,
   }),
   computed: {
      avatar() {
         return this.usr.avatar && config.img_url + this.usr.avatar;
      },
      no_update() {
         console.log(Object.keys(this.update, this.ch_passwd));
         return (Object.keys(this.update).length === 0 && !this.ch_passwd) && this.uid !== 'new';
      }
   },
   watch: {
      uid(val) {
         if(val) {
            if(val === 'new') {
               this.usr = {id: 'new'};
            } else {
               this.loading = true;
               api.get(val==='profile'?'profile':`user/${val}`).then((rsl) => {
                  if(rsl.data) {
                     this.usr = rsl.data;
                  }
               }).finally(() => this.loading = false)
            }
         } else {
            this.$refs.form.reset();
            this.update = {};
         }
      }
   },
   methods: {
      checkPasswd(v) {
         return v === this.usr.password || 'Password tidak sama';
      },
      // checkUserEmail() {
      //    if(!this.usr.username && !this.usr.email) return 'Username atau email diperlukan';
      //    if(this.usr.username && this.usr.username.length < 3) return 'Username minimal 3 karakter';
      //    if(this.usr.email && !/.+@.+\..+/.test(this.usr.email)) return 'Email harus valid';
      //    return true;
      // },
      close(reload) {
         this.$emit('close', reload);
      },
      async save() {
         if(!this.$refs.form.validate()) return;
         const {id, ...data} = this.usr;
         let rsl;
         this.load_save = true;
         if(id === 'new') rsl = await api.create('user', data);
         else {
            const {name} = this.update;
            if(name) {
               rsl = await api.edit(`profile`, id, {name});
               if(rsl.error) return this.$alert('Ooops!', rsl.error, 'error');
            }
            if(this.ch_passwd) {
               rsl = await api.edit(`user`, `${id}/password`, {password: data.password, retype_password: data.retype_password});
            }
         }
         this.load_save = false;
         if(rsl) {
            if(rsl.error) return this.$alert('Ooops!', rsl.error, 'error');
            this.$alert({title: 'Berhasil!', text: rsl.success, type: 'success', timer: 2000 });
            this.close(true);
         }
      },
   }
}
</script>
