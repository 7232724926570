<template>
   <v-card>
      <v-card-title>
         <v-text-field v-if="!!searchKey" flat prepend-icon="mdi-magnify" :placeholder="'Cari '+(searchKey.label).toLowerCase()+'...'" v-model="search" hide-details class="hidden-sm-and-down" ></v-text-field>
         <v-spacer></v-spacer>
         <slot name="add_btn" >
            <v-btn v-if="!noCreate" icon @click="openForm({})">
               <v-icon>mdi-plus</v-icon>
            </v-btn>
         </slot>
         <v-btn v-if="filters.length" icon @click="show_filter = !show_filter" title="filter">
            <v-icon>mdi-filter</v-icon>
         </v-btn>
      </v-card-title>
      <v-expand-transition>
         <v-card-text v-show="show_filter" class="grey lighten-3">
            <v-row dense>
               <v-col cols="12">Filter</v-col>
               <v-col cols="12" sm="6" md="4" v-for="(flt, i) in filters" :key="i">
                  <slot :name="`filter.${flt.value}`" v-bind="flt">
                     <v-select hide-details chips multiple :label="flt.filter_value && flt.label" :placeholder="`Select ${flt.label}...`" v-model="flt.filter_value" :items="flt.opts" @change="getData()" />
                  </slot>
               </v-col>
            </v-row>
         </v-card-text>
      </v-expand-transition>
      <v-card-text class="pa-0">
         <v-data-table
            :headers="headers"
            :search="search"
            :items="items"
            class="elevation-1"
            :server-items-length="total"
            :show-select="use_checkbox"
            v-model="selected"
            @update:page="chPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :loading="loading"
            :footer-props="footer"
            :items-per-page="limit"
            :disable-pagination="noPagination"
            :hide-default-footer="noPagination"
            @update:items-per-page="chLimit"
         >
            <template v-for="i in maped_item" v-slot:[i.key]="{ item }">
               {{ item[i.master[0]] && (item[i.master[0]][i.master[2]] || item[i.master[0]]) }}
            </template>
            <template v-for="i in maped_time" v-slot:[`item.${i}`]="{ item }">
               <span v-dater="item[i]" :key="i" />
            </template>
            <template v-for="i in maped_idr" v-slot:[`item.`+i]="{ item }">
               <div :key="item[i]" class="text-right">{{item[i] && item[i].toLocaleString('id-ID')}}</div>
            </template>
            <template  v-slot:[`item.act`]="{ item }">
               <slot name="row_act" v-bind="{...item}" />
               <v-btn v-if="withEdit" icon color="indigo" @click="openForm(item)">
                  <v-icon>mdi-pencil</v-icon>
               </v-btn>
               <v-btn v-if="withDelete" icon color="red" @click="remove(item[key_id])">
                  <v-icon>mdi-window-close</v-icon>
               </v-btn>
            </template>
         </v-data-table>
      </v-card-text>
      <v-dialog v-model="show_form" :fullscreen="formWidth==0" :max-width="formWidth || 500">
         <v-card>
            <v-app-bar flat prominent color="primary" dark>
               <v-toolbar-title class="display-1">{{fm[key_id] ? 'Edit':'Buat'}} {{title}}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn icon @click="closeForm">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-app-bar>
            <v-card-text>
               <v-form ref="form">
                  <v-row wrap>
                     <v-col v-for="inp in inputs" sm="12" v-bind:key="inp.name" >
                        <v-select v-if="inp.opts" :label="inp.label" v-model="fm[inp.value]" :items="inp.opts" />
                        <!-- <v-file-input v-else-if="inp.type === 'file'" :label="inp.label" @change="inp.change" /> -->
                        <v-text-field v-else :label="inp.label" :disabled="inp.ro" :name="inp.value" v-model="fm[inp.value]" :rules="inp.rules"/>
                     </v-col>
                     <v-spacer></v-spacer>
                  </v-row>     
               </v-form>
            </v-card-text>
            <v-card-actions class="pb-3 justify-center">
               <slot name="action" v-bind:form="$refs.form" v-bind:data="fm">
                  <v-btn outlined rounded width="180" color="primary" @click="save(fm)">save</v-btn>
               </slot>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-card>
</template>

<script>
import api from '../api';

export default {
   props: {
      title: String,
      apiPath: String,
      field: Array,
      withEdit: Boolean,
      formWidth: Number,
      noPagination: Boolean,
      actions: Function,
      formLink: String,
      keyId: String,
      withDelete: Boolean,
      noCreate: Boolean
   },
   data() {
      const searchKey = this.field.find(x => x.searchKey);
      return {
         sheet: false,
         sheet_value: {},
         use_checkbox: false,
         loading: false,
         search: searchKey ? '': undefined,
         searchKey,
         headers: this.field.filter(x => x.tbl).map(x => ({ text: x.label, value: x.value, sortable: !x.dis_sort })),
         selected: [],
         items: [],
         maped_item: this.field.filter(x => x.tbl && x.master).map(x => ({value: x.value,key:'item.'+x.value,master:x.master}) ),
         maped_time: this.field.filter(x => x.time).map(x => x.value),
         maped_idr: this.field.filter(x => x.idr).map(x => x.value),
         footer: {
            'items-per-page-options': [15,30,50,100],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
         },
         page: 1,
         sortBy: null,
         sortDesc: null,
         limit: 15,
         total: 0,
         key_id: this.field.find(x => x.label === '#').value,
         show_form: false,
         fm: {},
         inputs: this.field.filter(x => !x.ro),
         filters: this.field.filter(x => x.filter),
         show_filter: false,
      };
   },
   created() {
      this.field.forEach(x => {
         if(x.master) {
            api.get(x.master[3] || x.master[0]).then(rsl => {
               x.opts = rsl.data.map(y => ({
                  value: y[ x.master[1] ],
                  text: y[ x.master[2] ]
               }) );
            });
         }
      });
   },
   mounted () {
      this.getData();
   },
   methods: {
      rowAct(row) {
         this.sheet = true;
         this.sheet_value = row;
      },
      chPage(val) {
         this.page = val;
         this.getData();
      },
      chLimit(val) {
         this.limit = val;
         this.getData();
      },
      getData() {
         this.loading = true;
         let url = `${this.apiPath}?page=${this.page}&limit=${this.limit}`;
         if(this.searchKey && this.search !== '') url += `&${this.searchKey.value}=${this.search}`;
         this.filters.filter(x => x.filter_value && (x.filter_value+'').length > 0)
         .forEach(x => {
            url += `&${x.value}=${x.filter_value}`;
         });
         if(this.sortBy) {
            url += `&sort=${this.sortBy}${this.sortDesc ?'&desc=1':''}`;
         }
         api.get(url).then((rsl) => {
            if(rsl.data) {
               this.items = rsl.data;
               this.total = rsl.total;
            }
         }).finally(() => this.loading = false);
      },
      remove(id) {
         this.$alert({title: 'Perhatian!', text: `Yakin akan menghapus data #${id}?`, type: 'warning', ok: () => {
            api.del(this.apiPath, id).then(rsl => {
               if(rsl.status === 1) {
                  this.$alert({ title: 'Success', text: rsl.msg, type: 'success', timer: 2000, showConfirmButton: false})
                  this.getData();
               } else {
                  this.$alert('Ooops!', rsl.msg, 'error');
               }
            });

         },cancel: true });
      },
      openForm(data) {
         if(this.formLink) {
            this.$router.push(this.formLink +(data[this.keyId] || 'new'));
         } else {
            this.fm = {...data};
            this.show_form = true;
         }
      },
      closeForm() {
         this.$refs.form.reset();
         this.fm = {}
         this.show_form = false;
      },
      save() {
         if(!this.$refs.form.validate()) return;
         (this.fm[this.key_id] ?
            api.edit(this.apiPath, this.fm[this.key_id], this.fm)
         :
            api.create(this.apiPath, this.fm)
         ).then(rsl => {
            this.getData();
            if(rsl.success) {
               this.$alert({ title: 'Sukses!', text: rsl.success, type: 'success', timer: 2000, cb: () => { this.closeForm() }});
            } else this.$alert('Ooops!', rsl.error, 'error');
         })
      }
   },
   watch: {
      sortBy() {
         clearTimeout(this.sort_timer);
         this.sort_timer = setTimeout(this.getData ,50);
      },
      sortDesc() {
         clearTimeout(this.sort_timer);
         this.sort_timer = setTimeout(this.getData ,50);
      },
      search(o) {
         if(this.searchKey) this.getData();
         console.log('search', o);
      }
   }
};
</script>
