<template>
   <v-container>
      <crud
         api-path="user"
         title="User"
         :field="field"
         key-id="id"
         ref="crud"
      >
         <template v-slot:add_btn>
            <v-btn icon small color="indigo" title="buat user baru" @click="profile_id='new'"><v-icon>mdi-plus</v-icon></v-btn>
         </template>
         <template v-slot:row_act="row">
            <v-btn icon small color="indigo" title="edit user" @click="profile_id=row.id"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon small color="indigo" title="reset password" @click="reset(row)"><v-icon>mdi-key-change</v-icon></v-btn>
            <v-btn v-if="row.active_admin_status===0" class="mr-auto" color="green" icon title="activate" @click="activate(row)"><v-icon>mdi-check</v-icon></v-btn>
            <v-btn v-else class="mr-auto" color="red" icon title="inactivate" @click="activate(row)"><v-icon>mdi-cancel</v-icon></v-btn>
         </template>
      </crud>
      <user-form :uid="profile_id" v-on:close="closeFm"/>
   </v-container>
</template>

<script>
import crud from '@/components/crud';
import UserForm from '../components/UserForm.vue';
import api from '../api';

export default {
   components: { crud, UserForm, },
   data: () => ({
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'name', label: 'Nama Lengkap', tbl: true, searchKey: true },
         { value: 'email', label: 'Username', tbl: true, rules: [v => !!v || 'Email atau username harus diisi']},
         // { value: 'group_id', label: 'Group', master: ['user/group','id','name']},
         // { value: 'group_name', label: 'Group', tbl: true, ro: true},
         { value: 'active_admin_status', label: 'Status', tbl: true, filter: true, master: ['active_admin_status_name', 'status_id','status_name','list/activation_status']},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      profile_id: null,
   }),
   methods: {
      closeFm(reload) {
         this.profile_id = null;
         if(typeof reload === 'boolean') this.$refs.crud.getData();
      },
      reset({name, id}) {
         this.$alert({title: 'Perhatian!', text: `Yakin akan mereset password #${name}?`, type: 'warning', ok: () => {
            api.create(`user/${id}/reset_password`).then(rsl => {
               if(rsl.success) {
                  this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000, showConfirmButton: false})
               } else {
                  this.$alert('Ooops!', rsl.error, 'error');
               }
            });
         },cancel: true });
      },
      activate(usr) {
         const val = usr.active_admin_status === 1;
         this.$alert({ text: `${val?'Disable':'Enable'} user #${usr.name} ?`, type: 'warning',
            ok: () => {
               api.edit('user',`${usr.id}/admin_status`, {active_admin_status: val ? 0:1}).then(rsl => {
                  if(rsl.error) {
                     this.$alert('Ooops!', rsl.error, 'error');
                  }
                  if(rsl.success) {
                     this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000});
                     this.$refs.crud.getData();
                  }
               });
            },
            cancel: () => {}
         });
      },
   }
};
</script>
